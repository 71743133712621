export const environment = {
    inactivity: {
        initInactivity: 900,
        timeoutInactivity: 10,
        respawnInactivity: 10,
        countdown: 15
    },
    cognito: {
      userPoolID: "us-east-1_p96Mdgx7Q"
    },
    apis: {
      dataSentinel: "https://hn0k3erqyf.execute-api.us-east-1.amazonaws.com/DEV",
      userAccess: "https://yaufclr91m.execute-api.us-east-1.amazonaws.com/DEV/"
    }
}